import React from 'react';
import styled from 'styled-components';

import TopBar from 'components/screenshot/TopBar';

interface ScreenshotProps {
  imageName: string;
  title: string;
  hideTopBar?: boolean;
}

const Screenshot = styled.div`
  border-radius: 0 0 0.25rem 0.25rem;
  /* box-shadow: 1px 12px 30px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); */
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;

  @media (max-width: 719px) {
    margin-top: 30px;
  }
`;

const Image = styled.img`
  border-radius: 0 0 0.25rem 0.25rem;
`;

export default ({ title, imageName, hideTopBar }: ScreenshotProps) => {
  return (
    <Screenshot className="shadow">
      {!hideTopBar && <TopBar />}
      {imageName && <Image src={require(`assets/images/${imageName}`)} alt={`${title} screenshot`} />}
    </Screenshot>
  );
};

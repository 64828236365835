import React from 'react';
import styled from 'styled-components';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import Screenshot from 'components/screenshot/Screenshot';
import { Column } from 'components/grid/Column';
import { Row } from 'components/grid/Row';
import { Button } from 'components/button/Button';

import { responsiveFont } from 'styles/utils';
import { variables } from 'styles/variables';

interface ProjectProps {
  title: string;
  subtitle: string;
  description: string;
  tech?: string;
  liveLink?: string;
  downloadLink?: string;
  githubLink?: string;
  imageName: string;
  hideTopBar?: boolean;
}

const Project = styled.div`
  margin: 100px 0;
  min-height: 100vh;

  :last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 719px) {
    margin: 0 0 100px;
  }
`;

const RowStacked = styled(Row)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;

  @media (max-width: 719px) {
    margin: 0;
  }
`;

const RowTwoCols = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 7%;

  @media (max-width: 719px) {
    flex-direction: column;
  }
`;

const SimpleColumn = styled.div`
  width: 50%;

  @media (max-width: 719px) {
    width: 100%;
  }
`;

const InfoWrapper = styled.div`
  margin-top: 4px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 30px;
  gap: 15px;
`;

const Title = styled.div`
  margin: 0;
  font-size: ${responsiveFont(22, 32)};
  font-weight: 400;
  line-height: 2.3rem;
`;

const Subtitle = styled.div`
  margin: 4px 0 18px;
  font-size: ${responsiveFont(16, 20)};
  font-weight: 500;
`;

const Description = styled.div`
  margin: 0 0 30px;
  font-size: ${responsiveFont(14, 18)};
  /* text-align: justify; */
`;

const Tech = styled.div`
  font-size: ${responsiveFont(16, 20)};
  text-align: center;
`;

export default ({
  title,
  subtitle,
  description,
  tech,
  liveLink,
  // downloadLink,
  githubLink,
  imageName,
  hideTopBar,
}: ProjectProps) => {
  const [displayModal, changeDisplayModal] = React.useState(false);

  const openModal = () => {
    changeDisplayModal(true);
  };

  const closeModal = () => {
    changeDisplayModal(false);
  };

  const customStyles = {
    content: {
      maxWidth: '60%',
      top: '50%',
      left: '50%',
      // right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '50px',
    },
  };

  return (
    <Project>
      {/* <Modal
        open={displayModal}
        onClose={closeModal}
        role={`More info about ${title}`}
        // blockScroll={false}
        // focusTrapped={false}
        
        // container={document.getElementById('modal-container')!}
        // animationDuration={}
        center
      >
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <Description>{description}</Description>
      </Modal> */}
      <RowStacked>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </RowStacked>
      <RowTwoCols>
        <SimpleColumn sm={12} md={6} lg={5}>
          <InfoWrapper>
            <Description>{description}</Description>
            <Tech>{tech}</Tech>
          </InfoWrapper>
          {(liveLink || githubLink) && (
            <ButtonsWrapper>
              {/* <Button onClick={openModal}>More info</Button> */}
              {liveLink && <Button href={liveLink}>Live</Button>}
              {/* {downloadLink && (
              <Button href={downloadLink} download>
                Download my resume
              </Button>
            )} */}
              {githubLink && <Button href={githubLink}>Code</Button>}
            </ButtonsWrapper>
          )}
        </SimpleColumn>
        <SimpleColumn sm={12} md={6} lg={7}>
          <Screenshot
            imageName={imageName}
            title={title}
            hideTopBar={hideTopBar}
          />
        </SimpleColumn>
      </RowTwoCols>
    </Project>
  );
};

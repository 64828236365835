import styled from 'styled-components';

export const FullscreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 110vh;

  @media (min-width: 800px) {
    padding: 5vh 10% 5vh;
  }
  @media (max-width: 500px) {
    padding: 0 20px 100px;
  }

`;

import React from 'react';
import styled from 'styled-components';

import Title from 'components/sectiontitle/SectionTitle';
import { FullscreenContainer } from 'components/container/FullscreenContainer';

import { responsiveFont } from 'styles/utils';

const Paragraph = styled.p`
  margin-bottom: 30px;
  max-width: 600px;
  line-height: 1.6;

  font-size: ${responsiveFont(16, 20)};

  @media (max-width: 500px) {
    margin-bottom: 22px;
  }
`;

export default () => {
  return (
    <FullscreenContainer id="about">
      <Title>About</Title>
      <Paragraph>
        I'm a software engineer since 2019 and a longtime math and science nerd
        specializing in frontend JavaScript, React, and Vue. I'm passionate
        about making the world better with technology and strive to deliver a
        beautiful, intuitive interface for users while maintaining a clean
        codebase for fellow developers.
      </Paragraph>
      <Paragraph>
        I’ve have a long history with technology as a former research engineer,
        technical writer, and math teacher. My interest in programming was
        piqued when I taught myself to automate spreadsheet work with Visual
        Basic. After cramming HTML and CSS to lead a middle school coding
        elective, I was officially hooked.
      </Paragraph>
      <Paragraph>
        I'm happiest when exploring and most fulfilled when solving big problems
        to foster the common good. I love learning new technologies and delving
        deep into familiar ones. I'm inspired by the endless possibilities
        coding offers for carving structure out of chaos.
      </Paragraph>
    </FullscreenContainer>
  );
};

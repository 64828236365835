import React from 'react';

export default [
  {
    title: 'Gogigit / Designmate / Printmate',
    subtitle: 'Customized product design, marketplace, and printing',
    description: (
      <span>
        <div>
          With{' '}
          <a
            href="https://frontedgedigital.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Front Edge Digital
          </a>{' '}
          and{' '}
          <a
            href="https://sawgrassink.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sawgrass Technologies
          </a>
          , I worked on an integrated suite of web and desktop apps for
          creating, selling, and printing customized products.
        </div>
        <br></br>
        <div>
          For Gogigit, I worked on many parts of the core platform in Vue and
          WordPress. I led implementation of a feature for creating product
          images by merging product designs with lifestyle background photos.
        </div>
        <br />
        <div>
          For Designmate, I split off code from Gogigit into a separate,
          specialized app for creating and displaying designs. For Printmate, I
          built and refined the majority of a desktop printing app interface using Vue and
          Electron.
        </div>
        {/* <br />
        <br />
        Key tasks:
        <ul>
          <li>
            Gogigit: Implementing the lifestyle images feature - to merge
            existing designs with lifestyle background photos to create pretty
            featured product images.
          </li>
          <li>
            Printmate: Building and refining the desktop printing software
            interface including settings selectors, menus, and modals.
          </li>
          <li>
            Designmate: Splitting off the code for creating and displaying
            designs from Gogigit into a separate, specialized app
          </li>
        </ul> */}
      </span>
    ),
    tech: 'Vue, WordPress, Electron, Tailwind',
    tasks: [''],
    liveLink: 'https://beta.gogigit.com',
    imageName: 'gogigit-screenshot.png',
  },
  {
    title: 'Dele Detect',
    subtitle: 'Fall detection and alerting',
    tech: 'Vue, React Native, Bootstrap',
    description:
      'With Front Edge Digital, Northscaler, and Dele Health Tech, I worked on a set of web and mobile apps for nonintrusive patient monitoring and fall alerts. I architected and built the mobile app for fall alerting and worked on a team of two to build the web app front end that handled alerts, individual room monitoring, and data management for devices and facilities.',
    liveLink: '',
    imageName: 'delehealth-screenshot.png',
  },
  {
    title: 'Suite Solution',
    subtitle: 'Telecom account management',
    description:
      'With Front Edge Digital and Northflow Solutions, I worked on a small team that built a new version of a billing software. My work included internationalization and white-label configuration of client-branded colors, fonts, and design customizations.',
    tech: 'Vue, Bootstrap, Jest',
    liveLink: '',
    imageName: 'nsi-screenshot3.png',
  },
  {
    title: 'The Wally Shop',
    subtitle:
      'Reusable packaging e-commerce platform for sustainable grocery shopping',
    description:
      'I connected with The Wally Shop, a no-plastic personal shopping service, at an environmental justice hackathon and worked with them on a part-time, project-based contract. I helped develop the core web app, including improving the shopper portal, implementing a product rating system, and adding product photo galleries.',
    tech: 'React, Node.js, MongoDB, MaterialUI',
    liveLink: 'https://thewallyshop.co',
    imageName: 'wallyshop-screenshot.png',
  },
  {
    title: 'Sixcycle',
    subtitle: 'Multisport training and coaching',
    description:
      "The Sixcycle app is used by the Leukemia and Lymphoma Society's fundraising and training program. I owned the web app's front end for coaching and social features. I maintained all updates and bugfixes and added social features including saved message templates and public groups.",
    tech: 'React, JavaScript',
    liveLink: 'https://sixcycle.com',
    imageName: 'sixcycle-screenshot.png',
  },
  {
    title: 'Hello Pizza Truck',
    subtitle: 'Informational website for selling a food truck business',
    description:
      'When asked to proofread a sales pamphlet for a food truck, I envisioned the information displayed interactively on a website instead. Hello Pizza Truck was the result. I represented a complex pricing structure through user interface cues.',
    tech: 'React, Gatsby',
    liveLink:
      'https://62510cfb2aeada0009315ba7--dazzling-roentgen-0d1da6.netlify.app/',
    githubLink: 'https://github.com/theresamorelli/hello-pizza-truck',
    imageName: 'hellopizzatruck-screenshot.png',
  },
  {
    title: 'Byte Poet',
    subtitle: 'Digital magnetic poetry',
    description:
      'I built a fun app to spark poetic inspiration. Someday I plan to add more features like customizing word selection and saving/sharing poems.',
    tech: 'React, TypeScript, Styled Components',
    liveLink: 'https://inspiring-colden-24c655.netlify.app',
    githubLink: 'https://github.com/theresamorelli/byte-poet',
    imageName: 'bytepoet-screenshot.png',
  },
  {
    title: 'Newsmash',
    subtitle: 'News aggregator and data visualization',
    description:
      "This was my capstone project for Flatiron School's software engineering program. I wanted to create a way to compare stories on a given topic across news channels and also represent the data in a wordcloud. I integrated three external APIs (News API, ParallelDots, ZingChart).",
    tech: 'React, Ruby on Rails, Bootstrap',
    liveLink: 'http://newsmashed.herokuapp.com/mixes',
    githubLink: 'https://github.com/theresamorelli/newsmash-web',
    imageName: 'newsmash-screenshot.png',
  },
  {
    title: 'Refood',
    subtitle: 'Sharing food with neighbors',
    description:
      'Food waste and food insecurity are both major problems. Refood was built to solve both at once, redirecting extra food to the tables of those who need it. It simplifies posting food offers and arranging pickup and awards karma points for all participants.',
    tech: 'JavaScript, Ruby on Rails',
    githubLink: 'https://github.com/theresamorelli/refood-with-js',
    imageName: 'refood-screenshot.png',
  },
];
